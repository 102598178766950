import {styled} from '../../utils/styled/styled.js';

const StyledRoot = styled('span')(({theme}) => ({
    background: theme.palette.surface.base.high,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.depth.global.z7,
    position: 'fixed',
    top: 'calc(100vh - 70px)',
    height: '70px',
    paddingBottom: '16px',
    gap: theme.size.fixed.s0,

    [theme.query.l]: {
        display: 'none'
    }
}));

export {StyledRoot};
