import type {
    TAPIRequestContent,
    TAPIResponse,
    TKpisAnomaliesHistoryAPI,
    TKpisDoorFaultsTotalAPI,
    TKpisEnergyConsumptionDensityAPI,
    TKpisFloorDistributionAPI,
    TKpisMileageDensityAPI,
    TKpisMileageTotalAPI,
    TKpisOutOfServiceTotalAPI,
    TKpisSafetyTestsTotalAPI,
    TKpisSanitizationRunsTotalAPI,
    TKpisStopsDensityAPI,
    TKpisTimeConsumptionDensityAPI,
    TKpisTripsHistoryAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const getMileageTotal = (
    {query}: TAPIRequestContent<TKpisMileageTotalAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisMileageTotalAPI> => {
    return request<TKpisMileageTotalAPI>({
        method: 'GET',
        url: '/kpis/mileage-total',
        signal,
        query
    });
};

const getMileageDensity = (
    {query}: TAPIRequestContent<TKpisMileageDensityAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisMileageDensityAPI> => {
    return request<TKpisMileageDensityAPI>({
        method: 'GET',
        url: '/kpis/mileage-density',
        signal,
        query
    });
};

const getFloorDistribution = (
    {query}: TAPIRequestContent<TKpisFloorDistributionAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisFloorDistributionAPI> => {
    return request<TKpisFloorDistributionAPI>({
        method: 'GET',
        url: '/kpis/floor-distribution',
        signal,
        query
    });
};

const getTripsHistory = (
    {query}: TAPIRequestContent<TKpisTripsHistoryAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisTripsHistoryAPI> => {
    return request<TKpisTripsHistoryAPI>({
        method: 'GET',
        url: '/kpis/trips-history',
        signal,
        query
    });
};

const getOutOfServiceTotal = (
    {query}: TAPIRequestContent<TKpisOutOfServiceTotalAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisOutOfServiceTotalAPI> => {
    return request<TKpisOutOfServiceTotalAPI>({
        method: 'GET',
        url: '/kpis/out-of-service-total',
        signal,
        query
    });
};
const getDoorFaultsTotal = (
    {query}: TAPIRequestContent<TKpisDoorFaultsTotalAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisDoorFaultsTotalAPI> => {
    return request<TKpisDoorFaultsTotalAPI>({
        method: 'GET',
        url: '/kpis/door-faults-total',
        signal,
        query
    });
};
const getSafetyTestsTotal = (
    {query}: TAPIRequestContent<TKpisSafetyTestsTotalAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisSafetyTestsTotalAPI> => {
    return request<TKpisSafetyTestsTotalAPI>({
        method: 'GET',
        url: '/kpis/safety-tests-total',
        signal,
        query
    });
};
const getSanitizationRunsTotal = (
    {query}: TAPIRequestContent<TKpisSanitizationRunsTotalAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisSanitizationRunsTotalAPI> => {
    return request<TKpisSanitizationRunsTotalAPI>({
        method: 'GET',
        url: '/kpis/sanitization-runs-total',
        signal,
        query
    });
};

const getAnomaliesHistory = (
    {query}: TAPIRequestContent<TKpisAnomaliesHistoryAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisAnomaliesHistoryAPI> => {
    return request<TKpisAnomaliesHistoryAPI>({
        method: 'GET',
        url: '/kpis/anomalies-history',
        signal,
        query
    });
};

const getStopsDensity = (
    {query}: TAPIRequestContent<TKpisStopsDensityAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisStopsDensityAPI> => {
    return request<TKpisStopsDensityAPI>({
        method: 'GET',
        url: '/kpis/stops-density',
        signal,
        query
    });
};

const getEnergyConsumptionDenstiy = (
    {query}: TAPIRequestContent<TKpisEnergyConsumptionDensityAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisEnergyConsumptionDensityAPI> => {
    return request<TKpisEnergyConsumptionDensityAPI>({
        method: 'GET',
        url: '/kpis/energy-consumption-density',
        signal,
        query
    });
};

const getTimeConsumptionDensity = (
    {query}: TAPIRequestContent<TKpisTimeConsumptionDensityAPI>,
    signal: AbortSignal
): TAPIResponse<TKpisTimeConsumptionDensityAPI> => {
    return request<TKpisTimeConsumptionDensityAPI>({
        method: 'GET',
        url: '/kpis/time-consumption-density',
        signal,
        query
    });
};

export {
    getAnomaliesHistory,
    getDoorFaultsTotal,
    getEnergyConsumptionDenstiy,
    getFloorDistribution,
    getMileageDensity,
    getMileageTotal,
    getOutOfServiceTotal,
    getSafetyTestsTotal,
    getSanitizationRunsTotal,
    getStopsDensity,
    getTimeConsumptionDensity,
    getTripsHistory
};
