import type {IServiceCompanySummary, ISiteSummary} from '@mcal/core';
import type {ISliceRemote} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

type TLandingSliceStatus = 'INIT' | 'IDLE' | 'LOADING' | 'FAILED';

interface ILandingSliceState {
    status: TLandingSliceStatus;
    serviceCompanies: IServiceCompanySummary[];
    remotes: {
        serviceCompanies: ISliceRemote<IServiceCompanySummary[]>;
        sites: ISliceRemote<ISiteSummary[]>;
    };
}

const initialState = {
    status: 'INIT',
    serviceCompanies: [],
    remotes: {
        serviceCompanies: createRemote([]),
        sites: createRemote([])
    }
} satisfies ILandingSliceState as ILandingSliceState;

export type {ILandingSliceState, TLandingSliceStatus};
export {initialState};
