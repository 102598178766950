import {
    CorePalette,
    argbFromHex,
    hexFromArgb
} from '@material/material-color-utilities';
import type {
    TDSColorScales,
    TDSColorTokens,
    TDSColorTone,
    TDSColors
} from '../../defines/theme.types.js';

// https://m3.material.io/styles/color/static/baseline#690F18cd-d40f-4158-a358-4cfdb3a32768
// https://m3.material.io/styles/color/static/custom-brand#C752797c-5c9b-440a-8010-312f8c07d225
// https://m3.material.io/styles/color/dynamic/choosing-a-source
// https://m3.material.io/styles/color/resources
// https://m3.material.io/styles/color/advanced/overview

const tones: TDSColorTokens<number> = {
    '0': 0,
    '4': 4,
    '6': 6,
    '10': 10,
    '12': 12,
    '15': 15,
    '17': 17,
    '20': 20,
    '22': 22,
    '24': 24,
    '25': 25,
    '30': 30,
    '35': 35,
    '40': 40,
    '50': 50,
    '60': 60,
    '70': 70,
    '80': 80,
    '87': 87,
    '90': 90,
    '91': 91,
    '92': 92,
    '93': 93,
    '94': 94,
    '95': 95,
    '96': 96,
    '97': 97,
    '98': 98,
    '99': 99,
    '100': 100
};

const entries = Object.entries(tones) as [TDSColorTone, number][];

type TCorePaletteKeys = 'a1' | 'a2' | 'a3' | 'n1' | 'n2' | 'error';

function createColorScales(colors: TDSColors): TDSColorScales {
    const p1 = CorePalette.fromColors({
        primary: argbFromHex(colors.primary),
        secondary: argbFromHex(colors.secondary),
        tertiary: argbFromHex(colors.tertiary),
        neutral: argbFromHex(colors.neutral),
        neutralVariant: argbFromHex(colors.divergent),
        error: argbFromHex(colors.error)
    });

    const p2 = CorePalette.of(argbFromHex(colors.warning));
    const p3 = CorePalette.of(argbFromHex(colors.success));
    const p4 = CorePalette.of(argbFromHex(colors.info));

    const createScale = (
        palette: CorePalette,
        key: TCorePaletteKeys
    ): TDSColorTokens => {
        const scale: Partial<TDSColorTokens> = {};

        for (let i = 0; i < entries.length; i++) {
            const [k, v] = entries[i];

            scale[k] = hexFromArgb(palette[key].tone(v));
        }

        return scale as TDSColorTokens;
    };

    return {
        primary: createScale(p1, 'a1'),
        secondary: createScale(p1, 'a2'),
        tertiary: createScale(p1, 'a3'),
        neutral: createScale(p1, 'n1'),
        divergent: createScale(p1, 'n2'),
        error: createScale(p1, 'error'),
        warning: createScale(p2, 'a1'),
        success: createScale(p3, 'a1'),
        info: createScale(p4, 'a1')
    };
}

export {createColorScales};
