import {EEntityType} from '@mcal/core';
import type {ILinksMap} from '@mcal/core-react-web';
import {MainLayout, Text} from '@mcal/core-react-web';
import type {FC} from 'react';
import {lazy} from 'react';
import {createBrowserRouter, useRouteError} from 'react-router-dom';
import {HOME_LINKS, SERVICE_COMPANY_LINKS, SITE_LINKS} from './links.js';

const Sites = lazy(() =>
    import('@mcal/core-react-web/views/sites').then(({Sites}) => ({
        default: Sites
    }))
);

const ServiceCompanyOverview = lazy(() =>
    import('@mcal/core-react-web/views/service-company-overview').then(
        ({ServiceCompanyOverview}) => ({
            default: ServiceCompanyOverview
        })
    )
);

const Teams = lazy(() =>
    import('@mcal/core-react-web/views/teams').then(({Teams}) => ({
        default: Teams
    }))
);

const Elevators = lazy(() =>
    import('@mcal/core-react-web/views/elevators').then(({Elevators}) => ({
        default: Elevators
    }))
);

const Site = lazy(() =>
    import('@mcal/core-react-web/views/site').then(({Site}) => ({
        default: Site
    }))
);

const DashboardsHome = lazy(() =>
    import('@mcal/core-react-web/views/dashboards-home').then(
        ({DashboardsHome}) => ({
            default: DashboardsHome
        })
    )
);

const ElevatorDetails = lazy(() =>
    import('@mcal/core-react-web/views/elevator-details').then(
        ({ElevatorDetails}) => ({
            default: ElevatorDetails
        })
    )
);

const CreateServiceCompany = lazy(() =>
    import('@mcal/core-react-web/views/create-service-company').then(
        ({CreateServiceCompany}) => ({
            default: CreateServiceCompany
        })
    )
);

const CreateTechnicians = lazy(() =>
    import('@mcal/core-react-web/views/create-technicians').then(
        ({CreateTechnicians}) => ({
            default: CreateTechnicians
        })
    )
);

const Invites = lazy(() =>
    import('@mcal/core-react-web/views/invites').then(({Invites}) => ({
        default: Invites
    }))
);

const LogIn = lazy(() =>
    import('@mcal/core-react-web/views/log-in').then(({LogIn}) => ({
        default: LogIn
    }))
);

const SignUp = lazy(() =>
    import('@mcal/core-react-web/views/sign-up').then(({SignUp}) => ({
        default: SignUp
    }))
);

const ForgotPassword = lazy(() =>
    import('@mcal/core-react-web/views/forgot-password').then(
        ({ForgotPassword}) => ({
            default: ForgotPassword
        })
    )
);

const SiteSettings = lazy(() =>
    import('@mcal/core-react-web/views/site-settings').then(
        ({SiteSettings}) => ({
            default: SiteSettings
        })
    )
);

const ServiceCompanySettings = lazy(() =>
    import('@mcal/core-react-web/views/service-company-settings').then(
        ({ServiceCompanySettings}) => ({
            default: ServiceCompanySettings
        })
    )
);

const UserSettings = lazy(() =>
    import('./user-settings/user-settings.js').then(({UserSettings}) => ({
        default: UserSettings
    }))
);

const Error: FC = () => {
    const error = useRouteError();

    return (
        <>
            <Text variant={'h1'}>{'ERROR'}</Text>

            <Text variant={'monospace'}>
                <span style={{whiteSpace: 'pre'}}>
                    {JSON.stringify(error, null, 2)}
                </span>
            </Text>
        </>
    );
};

const linksMap: ILinksMap = {
    serviceCompanies: SERVICE_COMPANY_LINKS,
    sites: SITE_LINKS,
    home: HOME_LINKS
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout linksMap={linksMap} />,
        errorElement: <Error />,
        children: [
            // Home routes
            {
                path: '/',
                element: <DashboardsHome />,
                errorElement: <Error />
            },
            {
                path: '/user-settings',
                element: <UserSettings />,
                errorElement: <Error />
            },
            {
                path: '/invites',
                element: <Invites />,
                errorElement: <Error />
            },

            // Site routes
            {
                path: '/site/:siteId',
                children: [
                    {
                        path: '/site/:siteId/overview',
                        element: <Site />,
                        errorElement: <Error />
                    },
                    {
                        path: '/site/:siteId/elevators',
                        element: <Elevators />,
                        errorElement: <Error />,
                        children: [
                            {
                                path: ':elevatorId',
                                element: <ElevatorDetails />
                            },
                            {
                                path: ':elevatorId/teams',
                                element: (
                                    <Teams entityType={EEntityType.Elevator} />
                                ),
                                errorElement: <Error />
                            }
                        ]
                    },
                    {
                        path: '/site/:siteId/teams',
                        element: <Teams entityType={EEntityType.Site} />,
                        errorElement: <Error />
                    },
                    {
                        path: '/site/:siteId/settings',
                        element: <SiteSettings />,
                        errorElement: <Error />
                    }
                ]
            },

            // Service Company routes
            {
                path: '/service-companies/:serviceCompanyId',
                children: [
                    {
                        path: '/service-companies/:serviceCompanyId/overview',
                        element: <ServiceCompanyOverview />,
                        errorElement: <Error />
                    },
                    {
                        path: '/service-companies/:serviceCompanyId/sites',
                        element: <Sites />,
                        errorElement: <Error />
                    },
                    {
                        path: '/service-companies/:serviceCompanyId/teams',
                        element: (
                            <Teams entityType={EEntityType.ServiceCompany} />
                        ),
                        errorElement: <Error />
                    },
                    {
                        path: '/service-companies/:serviceCompanyId/settings',
                        element: <ServiceCompanySettings />,
                        errorElement: <Error />
                    }
                ]
            }
        ]
    },

    // Auth routes
    {
        path: '/log-in',
        element: <LogIn />,
        errorElement: <Error />
    },
    {
        path: '/sign-up',
        element: <SignUp />,
        errorElement: <Error />
    },
    {
        path: '/create-service-company',
        element: <CreateServiceCompany />,
        errorElement: <Error />
    },
    {
        path: '/create-technician',
        element: <CreateTechnicians />,
        errorElement: <Error />
    },
    {
        path: '/recover-password',
        element: <ForgotPassword />,
        errorElement: <Error />
    }
]);

export {router};
