import type {ReactNode} from 'react';
import type {
    TCheats,
    TCheatsDescriptors
} from '../../hooks/use-cheats/use-cheats.js';
import {useCheats} from '../../hooks/use-cheats/use-cheats.js';

interface ICheatsProps<TD extends TCheatsDescriptors> {
    cheats: TCheats<TD>;
}

const Cheats = <TD extends TCheatsDescriptors>({
    cheats
}: ICheatsProps<TD>): ReactNode => {
    useCheats(cheats);
    return null;
};

export type {ICheatsProps};
export {Cheats};
