import type {TPermission} from '../../defines/platform/permissions.js';

type TStrategy = 'some' | 'every';

function hasPermission(
    owned: TPermission[],
    required: TPermission[],
    strategy: TStrategy = 'every'
): boolean {
    if (!required.length) {
        return true;
    }

    return required[strategy]((p1) => owned.find((p2) => p1 === p2));
}

export {hasPermission};
