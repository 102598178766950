import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IDoorSlidingSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const DoorSlidingSvg: FC<IDoorSlidingSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={fill}
            {...props}
        >
            <mask
                id={'mask0_835_2785'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
            </mask>
            <g mask={'url(#mask0_835_2785)'}>
                <path
                    d={
                        'M3.75 17C3.5375 17 3.35938 16.9273 3.21563 16.7818C3.07188 16.6364 3 16.4561 3 16.2411C3 16.0387 3.07292 15.8646 3.21875 15.7188C3.36458 15.5729 3.54167 15.5 3.75 15.5H4V4.5C4 4.0875 4.14687 3.73438 4.44062 3.44063C4.73438 3.14688 5.0875 3 5.5 3H9.5V15.5H10.5V3H14.5C14.9125 3 15.2656 3.14688 15.5594 3.44063C15.8531 3.73438 16 4.0875 16 4.5V15.5H16.25C16.4625 15.5 16.6406 15.5715 16.7844 15.7144C16.9281 15.8573 17 16.0344 17 16.2456C17 16.4569 16.9281 16.6354 16.7844 16.7812C16.6406 16.9271 16.4625 17 16.25 17H3.75Z'
                    }
                    fill={fill || colorA}
                />
            </g>
        </svg>
    );
};

export {DoorSlidingSvg};
