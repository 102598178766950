import type {
    IBaseQuery,
    TAPIArgs,
    TAPIResponseError,
    TAPIResponseSuccess,
    TAPIUnknown,
    TDistributiveOmit,
    TIfEmpty,
    TNestedPartial,
    TObj,
    TRequestStatus
} from '@mcal/core';
import type {
    Action,
    AsyncThunk,
    AsyncThunkAction,
    EnhancedStore,
    ThunkAction,
    ThunkDispatch,
    UnknownAction
} from '@reduxjs/toolkit';
import type {TypedUseSelectorHook} from 'react-redux';
import type {IAppSliceState} from '../slices/app/app.state.js';
import type {IBLESliceState} from '../slices/ble/ble.state.js';
import type {IElevatorSliceState} from '../slices/elevator/elevator.state.js';
import type {ILandingSliceState} from '../slices/landing/landing.state.js';
import type {ILogInSliceState} from '../slices/log-in/log-in.state.js';
import type {IMServiceSliceState} from '../slices/m-service/m-service.state.js';
import type {IMSetupSliceState} from '../slices/m-setup/m-setup.state.js';
import type {IMWaySliceState} from '../slices/m-way/m-way.state.js';
import type {notificationsActions} from '../slices/notifications/notifications.index.js';
import type {INotificationsSliceState} from '../slices/notifications/notifications.state.js';
import type {IPlatformSliceState} from '../slices/platform/platform.state.js';
import type {IServiceCompanySliceState} from '../slices/service-company/service-company.state.js';
import type {ISessionSliceState} from '../slices/session/session.state.js';
import type {ISignUpSliceState} from '../slices/sign-up/sign-up.state.js';
import type {ISiteSliceState} from '../slices/site/site.state.js';
import type {IUserSliceState} from '../slices/user/user.state.js';
import type {INotificationOption} from './platform.types.js';

// https://redux.js.org/usage/usage-with-typescript
// https://react-redux.js.org/using-react-redux/usage-with-typescript
// https://redux-toolkit.js.org/usage/usage-with-typescript

/*
 * OTHER RELEVANT LINKS:
 * https://github.com/reduxjs/redux-toolkit/issues/766#issuecomment-711415387
 */

type TStore = EnhancedStore;

interface IPartialState {
    user?: IUserSliceState;
    session?: ISessionSliceState;
    logIn?: ILogInSliceState;
    signUp?: ISignUpSliceState;
    serviceCompany?: IServiceCompanySliceState;
    platform?: IPlatformSliceState;
    site?: ISiteSliceState;
    elevator?: IElevatorSliceState;
    notifications?: INotificationsSliceState;
    app?: IAppSliceState;
    landing?: ILandingSliceState;
    ble?: IBLESliceState;
    mSetup?: IMSetupSliceState;
    mService?: IMServiceSliceState;
    mWay?: IMWaySliceState;
}

// https://github.com/reduxjs/redux-toolkit/issues/472

type TNotificationAction = ReturnType<typeof notificationsActions.send>;

interface IDispatchExtNotification {
    // SPECIFIC OVERLOAD (NOTIFICATIONS)
    /** Accepts a notification action object, and returns a notification promise */
    (action: TNotificationAction): Promise<INotificationOption | null>;
}

interface IDispatchExtEmpty {}

// INSPIRED BY: https://github.com/reduxjs/redux-thunk/blob/master/src/types.ts
interface IDispatch<
    TState = IPartialState,
    TExtraThunkArg = unknown,
    TBasicAction extends Action = UnknownAction
> {
    // IMPORTANT: ORDER OF OVERLOADS MATTERS. DO NOT MODIFY THIS TYPE IF UNSURE.

    // SPECIFIC OVERLOAD (THUNK FUNCTION)
    /** Accepts a thunk function, runs it, and returns whatever the thunk itself returns */
    <TReturnType>(
        thunkAction: ThunkAction<
            TReturnType,
            TState,
            TExtraThunkArg,
            TBasicAction
        >
    ): TReturnType;

    // SPECIFIC OVERLOAD (NOTIFICATIONS)
    /** Accepts a notification action object, and returns a notification promise */
    (action: TNotificationAction): Promise<INotificationOption | null>;

    // BASE OVERLOAD
    /** Accepts a standard action object, and returns that action object */
    <TAction extends TBasicAction>(action: TAction): TAction;

    // UNION OF ALL OTHER OVERLOADS (SEE: https://github.com/microsoft/TypeScript/issues/14107)
    /** A union of the other overloads for TS inference purposes */
    <TReturnType, TAction extends TBasicAction>(
        action:
            | TAction
            | TNotificationAction
            | ThunkAction<TReturnType, TState, TExtraThunkArg, TBasicAction>
    ): TAction | Promise<INotificationOption | null> | TReturnType;
}

type TUnknownDispatch<TExtra = unknown> = ThunkDispatch<
    IPartialState,
    TExtra,
    UnknownAction
>;

type TTypedUseSelectorHook<T> = TypedUseSelectorHook<T>;

type TSelector<T> = (state: IPartialState) => T;

// https://redux-toolkit.js.org/usage/usage-with-typescript#typing-the-thunkapi-object
interface IAsyncThunkConfig<TRejected = unknown> {
    state: IPartialState;
    dispatch: IDispatch;
    extra?: unknown;
    rejectValue: TRejected;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
}

type TAsyncThunk<
    TReturned = unknown,
    TThunkArg = unknown,
    TRejected = unknown
> = AsyncThunk<TReturned, TThunkArg, IAsyncThunkConfig<TRejected>>;

type TAsyncThunkAction<
    TReturned = unknown,
    TThunkArg = unknown,
    TRejected = unknown
> = AsyncThunkAction<TReturned, TThunkArg, IAsyncThunkConfig<TRejected>>;

type TAsyncThunkActionPromise<
    TReturned = unknown,
    TThunkArg = unknown,
    TRejected = unknown
> = ReturnType<TAsyncThunkAction<TReturned, TThunkArg, TRejected>>;

type TEntitySliceStatus = 'INIT' | 'IDLE' | 'LOADING' | 'FAILED';

interface ISliceSource<TData> {
    status: TRequestStatus;
    prev: TData | null;
}

/*
 * INIT: THE SOURCE IS AT THE INITIAL STATE
 * LOADING: THE SOURCE IS LOADING FOR THE FIRST TIME
 * READY: THE SOURCE IS READY FOR USE
 * UPDATING: THE SOURCE IS BEING UPDATED, BUT CURRENT DATA IS STILL AVAILABLE
 * ERROR: THE SOURCE FAILED TO LOAD (ONLY WHEN TRANSITIONING FROM INIT)
 */
enum ESliceRemoteStatus {
    Init = 'init',
    Loading = 'loading',
    Ready = 'ready',
    Updating = 'updating',
    Error = 'error'
}

interface IRemoteUpdate<TData extends object> {
    requestId: string;
    timestamp: number;
    rollback: TData;
    startingStatus: ESliceRemoteStatus;
}

interface ISliceRemote<TData extends object> {
    status: ESliceRemoteStatus;
    current: TData;
    updatedAt: number;
    errorCode: string | null;
    updates: Record<string, IRemoteUpdate<TData>>;
}

interface IRemoteDescriptor<
    TData extends object,
    TReturned,
    TThunkArg,
    TThunkApiConfig extends IAsyncThunkConfig
> {
    selector: TSelector<ISliceRemote<TData>>;
    thunk: AsyncThunk<TReturned, TThunkArg, TThunkApiConfig>;
    maxAge: number;
}

interface IStateWithRemotes {
    remotes: TObj<ISliceRemote<object>>;
}

type TRemoteKeys<TState extends IStateWithRemotes> = keyof TState['remotes'];

type TRemoteUpdateTarget<
    TState extends IStateWithRemotes,
    TArg,
    TKey extends keyof TState['remotes']
> =
    | {
          key: TKey;
          mutator: (
              arg: TArg,
              current: TState['remotes'][TKey]['current']
          ) => void;
      }
    | {
          key: TKey;
          replacer: (
              arg: TArg,
              current: TState['remotes'][TKey]['current']
          ) => TState['remotes'][TKey]['current'];
      }
    | {
          key: TKey;
          merger: (
              arg: TArg,
              current: TState['remotes'][TKey]['current']
          ) => TNestedPartial<TState['remotes'][TKey]['current']>;
      };

type TRemoteUpdateTargets<TState extends IStateWithRemotes, TArg> = {
    [k in keyof TState['remotes']]: TRemoteUpdateTarget<TState, TArg, k>;
}[keyof TState['remotes']];

type TAPIAsyncThunkReturned<TAPI extends TAPIUnknown> =
    TAPIResponseSuccess<TAPI>;

type TAPIMinimumArgs<
    TAPI extends TAPIUnknown,
    TExcept extends string | void = void
> = TDistributiveOmit<
    TAPIArgs<TAPI>,
    TExcept extends string ? keyof IBaseQuery | TExcept : keyof IBaseQuery
>;

type TAPIAsyncThunkArgs<
    TAPI extends TAPIUnknown,
    TExtra extends object | void = void,
    TExcept extends string | void = void
> = TIfEmpty<
    TExtra extends void
        ? TAPIMinimumArgs<TAPI, TExcept>
        : TAPIMinimumArgs<TAPI, TExcept> & TExtra
>;

type TAPIAsyncThunkConfig<TAPI extends TAPIUnknown> = IAsyncThunkConfig<
    TAPIResponseError<TAPI>
>;

export type {
    IAsyncThunkConfig,
    IDispatch,
    IDispatchExtEmpty,
    IDispatchExtNotification,
    IPartialState,
    IRemoteDescriptor,
    IRemoteUpdate,
    ISliceRemote,
    ISliceSource,
    IStateWithRemotes,
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig,
    TAPIAsyncThunkReturned,
    TAPIMinimumArgs,
    TAsyncThunk,
    TAsyncThunkAction,
    TAsyncThunkActionPromise,
    TEntitySliceStatus,
    TNotificationAction,
    TRemoteKeys,
    TRemoteUpdateTarget,
    TRemoteUpdateTargets,
    TSelector,
    TStore,
    TTypedUseSelectorHook,
    TUnknownDispatch
};
export {ESliceRemoteStatus};
