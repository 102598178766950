import type {IServiceCompanySummary, ISiteSummary} from '@mcal/core';
import {EEntityType, PLATFORM_ID} from '@mcal/core';
import {createSlice} from '@reduxjs/toolkit';
import {listServiceCompanies} from '../../services/app-node-service-companies/service-companies/service-companies.thunks.js';
import {listSites} from '../../services/app-node-sites/sites/sites.thunks.js';
import {fulfilledRemoteUpdate} from '../../utils/fulfilled-remote-update/fulfilled-remote-update.js';
import {pendingRemoteUpdate} from '../../utils/pending-remote-update/pending-remote-update.js';
import {rejectedRemoteUpdate} from '../../utils/rejected-remote-update/rejected-remote-update.js';
import {initialState} from './landing.state.js';

const landingSlice = createSlice({
    name: 'landing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(listServiceCompanies.pending, (state, {meta}) => {
            if (meta.arg.scope !== EEntityType.Platform) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['serviceCompanies']
            });
        });
        builder.addCase(
            listServiceCompanies.fulfilled,
            (state, {payload, meta}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'serviceCompanies',
                            replacer: (data): IServiceCompanySummary[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listServiceCompanies.rejected,
            (state, {meta, payload}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanies']
                });
            }
        );

        builder.addCase(listSites.pending, (state, {meta}) => {
            if (meta.arg.serviceCompanyId !== PLATFORM_ID) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['sites']
            });
        });
        builder.addCase(listSites.fulfilled, (state, {payload, meta}) => {
            if (meta.arg.serviceCompanyId !== PLATFORM_ID) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'sites',
                        replacer: (data): ISiteSummary[] => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(listSites.rejected, (state, {meta, payload}) => {
            if (meta.arg.serviceCompanyId !== PLATFORM_ID) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['sites']
            });
        });
    }
});

export {landingSlice};
