import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface ITeamSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const TeamSvg: FC<ITeamSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            {...props}
        >
            <mask
                id={'mask0_835_2792'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
            </mask>
            <g mask={'url(#mask0_835_2792)'}>
                <path
                    d={
                        'M7.50517 13.5C7.98783 13.5 8.39931 13.3281 8.73958 12.9844C9.07986 12.6407 9.25 12.2275 9.25 11.7448C9.25 11.2622 9.07814 10.8507 8.73442 10.5104C8.39069 10.1701 7.9775 10 7.49483 10C7.01217 10 6.60069 10.1719 6.26042 10.5156C5.92014 10.8593 5.75 11.2725 5.75 11.7552C5.75 12.2378 5.92186 12.6493 6.26558 12.9896C6.60931 13.3299 7.0225 13.5 7.50517 13.5ZM12.5052 13.5C12.9878 13.5 13.3993 13.3281 13.7396 12.9844C14.0799 12.6407 14.25 12.2275 14.25 11.7448C14.25 11.2622 14.0781 10.8507 13.7344 10.5104C13.3907 10.1701 12.9775 10 12.4948 10C12.0122 10 11.6007 10.1719 11.2604 10.5156C10.9201 10.8593 10.75 11.2725 10.75 11.7552C10.75 12.2378 10.9219 12.6493 11.2656 12.9896C11.6093 13.3299 12.0225 13.5 12.5052 13.5ZM10.0052 9.25C10.4878 9.25 10.8993 9.07814 11.2396 8.73442C11.5799 8.39069 11.75 7.9775 11.75 7.49483C11.75 7.01217 11.5781 6.60069 11.2344 6.26042C10.8907 5.92014 10.4775 5.75 9.99483 5.75C9.51217 5.75 9.10069 5.92186 8.76042 6.26558C8.42014 6.60931 8.25 7.0225 8.25 7.50517C8.25 7.98783 8.42186 8.39931 8.76558 8.73958C9.10931 9.07986 9.5225 9.25 10.0052 9.25ZM10.0058 18C8.9047 18 7.86806 17.7917 6.89583 17.375C5.92361 16.9583 5.07292 16.3854 4.34375 15.6562C3.61458 14.9271 3.04167 14.0767 2.625 13.105C2.20833 12.1334 2 11.0952 2 9.99046C2 8.88571 2.20833 7.85069 2.625 6.88542C3.04167 5.92014 3.61458 5.07292 4.34375 4.34375C5.07292 3.61458 5.92332 3.04167 6.89496 2.625C7.86661 2.20833 8.90481 2 10.0095 2C11.1143 2 12.1493 2.20833 13.1146 2.625C14.0799 3.04167 14.9271 3.61458 15.6562 4.34375C16.3854 5.07292 16.9583 5.92169 17.375 6.89008C17.7917 7.85849 18 8.89321 18 9.99425C18 11.0953 17.7917 12.1319 17.375 13.1042C16.9583 14.0764 16.3854 14.9271 15.6562 15.6562C14.9271 16.3854 14.0783 16.9583 13.1099 17.375C12.1415 17.7917 11.1068 18 10.0058 18Z'
                    }
                    fill={fill || colorA}
                />
            </g>
        </svg>
    );
};

export {TeamSvg};
