import type {
    TAPIRequestContent,
    TAPIResponse,
    TElevatorConfigurationsTemplateAPI,
    TElevatorEmergenciesAPI,
    TElevatorEmergencyRestoreAPI,
    TElevatorFullHandoffAPI,
    TElevatorGetAPI,
    TElevatorGetAnomaliesAPI,
    TElevatorGetDestinationsAPI,
    TElevatorGetDevicesAPI,
    TElevatorGetIssuesAPI,
    TElevatorGetStateAPI,
    TElevatorGetStatusAPI,
    TElevatorGetStopsAPI,
    TElevatorGetTechnicalParametersAPI,
    TElevatorGetTripsAPI,
    TElevatorOperationModeAPI,
    TElevatorRemoteControlAPI,
    TElevatorRemoteControlCallAPI,
    TElevatorRemoteControlMoveAPI,
    TElevatorTeamDeleteAPI,
    TElevatorTeamGetAPI,
    TElevatorTeamUpdateAPI,
    TElevatorUpdateConfigurationAdvancedAPI,
    TElevatorUpdateConfigurationSensorsAPI,
    TElevatorUpdateConfigurationSystemPlateAPI,
    TElevatorUpdateDestinationsAPI,
    TElevatorUpdateProfileAPI,
    TElevatorUpdateStopsAPI,
    TElevatorUpdateTechnicalParametersAPI,
    TElevatorsListAPI,
    TElevatorsRegisterAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const registerElevators = (
    {query, body}: TAPIRequestContent<TElevatorsRegisterAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorsRegisterAPI> => {
    return request<TElevatorsRegisterAPI>({
        method: 'PUT',
        url: '/elevators/register',
        signal,
        query,
        body,
        baseURL: 'https://api.dev.mcal.codistica.net/'
    });
};

const listElevators = (
    {query}: TAPIRequestContent<TElevatorsListAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorsListAPI> => {
    return request<TElevatorsListAPI>({
        method: 'GET',
        url: '/elevators',
        signal,
        query
    });
};

const listElevatorDestinations = (
    {query}: TAPIRequestContent<TElevatorGetDestinationsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetDestinationsAPI> => {
    return request<TElevatorGetDestinationsAPI>({
        method: 'GET',
        url: '/elevator/destinations',
        signal,
        query
    });
};

const listElevatorStops = (
    {query}: TAPIRequestContent<TElevatorGetStopsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetStopsAPI> => {
    return request<TElevatorGetStopsAPI>({
        method: 'GET',
        url: '/elevator/stops',
        signal,
        query
    });
};

const getElevatorEmergency = (
    {query}: TAPIRequestContent<TElevatorEmergenciesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorEmergenciesAPI> => {
    return request<TElevatorEmergenciesAPI>({
        method: 'GET',
        url: '/elevator/emergencies',
        signal,
        query
    });
};

const restoreElevatorEmergency = (
    {query, body}: TAPIRequestContent<TElevatorEmergencyRestoreAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorEmergencyRestoreAPI> => {
    return request<TElevatorEmergencyRestoreAPI>({
        method: 'POST',
        url: '/elevator/emergency/restore',
        signal,
        body,
        query
    });
};

const updateElevatorStops = (
    {query, body}: TAPIRequestContent<TElevatorUpdateStopsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateStopsAPI> => {
    return request<TElevatorUpdateStopsAPI>({
        method: 'PATCH',
        url: '/elevator/stops',
        signal,
        body,
        query
    });
};

const updateElevatorDestinations = (
    {query, body}: TAPIRequestContent<TElevatorUpdateDestinationsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateDestinationsAPI> => {
    return request<TElevatorUpdateDestinationsAPI>({
        method: 'PATCH',
        url: '/elevator/destinations',
        signal,
        body,
        query
    });
};

const getElevator = (
    {query}: TAPIRequestContent<TElevatorGetAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetAPI> => {
    return request<TElevatorGetAPI>({
        method: 'GET',
        url: '/elevator',
        signal,
        query
    });
};

const getElevatorState = (
    {query}: TAPIRequestContent<TElevatorGetStateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetStateAPI> => {
    return request<TElevatorGetStateAPI>({
        method: 'GET',
        url: '/elevator/state',
        signal,
        query
    });
};

const getElevatorDevices = (
    {query}: TAPIRequestContent<TElevatorGetDevicesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetDevicesAPI> => {
    return request<TElevatorGetDevicesAPI>({
        method: 'GET',
        url: '/elevator/devices',
        signal,
        query
    });
};

const getElevatorTechnicalParameters = (
    {query}: TAPIRequestContent<TElevatorGetTechnicalParametersAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetTechnicalParametersAPI> => {
    return request<TElevatorGetTechnicalParametersAPI>({
        method: 'GET',
        url: '/elevator/technical-parameters',
        signal,
        query
    });
};

const getElevatorIssues = (
    {query}: TAPIRequestContent<TElevatorGetIssuesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetIssuesAPI> => {
    return request<TElevatorGetIssuesAPI>({
        method: 'GET',
        url: '/elevator/issues',
        signal,
        query
    });
};

const getElevatorAnomalies = (
    {query}: TAPIRequestContent<TElevatorGetAnomaliesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetAnomaliesAPI> => {
    return request<TElevatorGetAnomaliesAPI>({
        method: 'GET',
        url: '/elevator/anomalies',
        signal,
        query
    });
};

const getElevatorTrips = (
    {query}: TAPIRequestContent<TElevatorGetTripsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetTripsAPI> => {
    return request<TElevatorGetTripsAPI>({
        method: 'GET',
        url: '/elevator/trips',
        signal,
        query
    });
};

const updateElevatorTechnicalParameters = (
    {query, body}: TAPIRequestContent<TElevatorUpdateTechnicalParametersAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateTechnicalParametersAPI> => {
    return request<TElevatorUpdateTechnicalParametersAPI>({
        method: 'PATCH',
        url: '/elevator/technical-parameters',
        signal,
        body,
        query
    });
};

const getElevatorConfigurationTemplate = (
    {query}: TAPIRequestContent<TElevatorConfigurationsTemplateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorConfigurationsTemplateAPI> => {
    return request<TElevatorConfigurationsTemplateAPI>({
        method: 'GET',
        url: '/elevator/configuration',
        signal,
        query
    });
};

const updateElevatorSystemPlate = (
    {
        query,
        body
    }: TAPIRequestContent<TElevatorUpdateConfigurationSystemPlateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateConfigurationSystemPlateAPI> => {
    return request<TElevatorUpdateConfigurationSystemPlateAPI>({
        method: 'PATCH',
        url: '/elevator/configuration/system-config',
        signal,
        body,
        query
    });
};

const updateElevatorSensors = (
    {query, body}: TAPIRequestContent<TElevatorUpdateConfigurationSensorsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateConfigurationSensorsAPI> => {
    return request<TElevatorUpdateConfigurationSensorsAPI>({
        method: 'PATCH',
        url: '/elevator/configuration/sensors',
        signal,
        body,
        query
    });
};

const updateElevatorAdvanced = (
    {query, body}: TAPIRequestContent<TElevatorUpdateConfigurationAdvancedAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateConfigurationAdvancedAPI> => {
    return request<TElevatorUpdateConfigurationAdvancedAPI>({
        method: 'PATCH',
        url: '/elevator/configuration/advanced',
        signal,
        body,
        query
    });
};

const updateElevatorProfile = (
    {query, body}: TAPIRequestContent<TElevatorUpdateProfileAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateProfileAPI> => {
    return request<TElevatorUpdateProfileAPI>({
        method: 'PATCH',
        url: '/elevator/profile',
        signal,
        query,
        body
    });
};

const getElevatorTeam = (
    {query}: TAPIRequestContent<TElevatorTeamGetAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorTeamGetAPI> => {
    return request<TElevatorTeamGetAPI>({
        method: 'GET',
        url: '/elevator/team',
        signal,
        query
    });
};

const updateElevatorTeam = (
    {query, body}: TAPIRequestContent<TElevatorTeamUpdateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorTeamUpdateAPI> => {
    return request<TElevatorTeamUpdateAPI>({
        method: 'PATCH',
        url: '/elevator/team',
        signal,
        query,
        body
    });
};

const deleteElevatorTeam = (
    {query, body}: TAPIRequestContent<TElevatorTeamDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorTeamDeleteAPI> => {
    return request<TElevatorTeamDeleteAPI>({
        method: 'DELETE',
        url: '/elevator/team',
        signal,
        query,
        body
    });
};

const updateElevatorOperationMode = (
    {query, body}: TAPIRequestContent<TElevatorOperationModeAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorOperationModeAPI> => {
    return request<TElevatorOperationModeAPI>({
        method: 'PATCH',
        url: '/elevator/operation-mode',
        signal,
        query,
        body
    });
};

const updateElevatorHandoff = (
    {query, body}: TAPIRequestContent<TElevatorFullHandoffAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorFullHandoffAPI> => {
    return request<TElevatorFullHandoffAPI>({
        method: 'PATCH',
        url: '/elevator/full-handoff',
        signal,
        query,
        body
    });
};

const remoteControlCall = (
    {query, body}: TAPIRequestContent<TElevatorRemoteControlCallAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRemoteControlCallAPI> => {
    return request<TElevatorRemoteControlCallAPI>({
        method: 'POST',
        url: '/elevator/remote-call',
        signal,
        query,
        body
    });
};

const remoteMove = (
    {query, body}: TAPIRequestContent<TElevatorRemoteControlMoveAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRemoteControlMoveAPI> => {
    return request<TElevatorRemoteControlMoveAPI>({
        method: 'POST',
        url: '/elevator/remote-move',
        signal,
        query,
        body
    });
};

const updateRemoteControlMode = (
    {query, body}: TAPIRequestContent<TElevatorRemoteControlAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRemoteControlAPI> => {
    return request<TElevatorRemoteControlAPI>({
        method: 'PATCH',
        url: '/elevator/remote-control',
        signal,
        query,
        body
    });
};

const getElevatorStatus = (
    {query}: TAPIRequestContent<TElevatorGetStatusAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetStatusAPI> => {
    return request<TElevatorGetStatusAPI>({
        method: 'GET',
        url: '/elevator/status',
        signal,
        query
    });
};

export {
    deleteElevatorTeam,
    getElevator,
    getElevatorAnomalies,
    getElevatorConfigurationTemplate,
    getElevatorDevices,
    getElevatorEmergency,
    getElevatorIssues,
    getElevatorState,
    getElevatorStatus,
    getElevatorTeam,
    getElevatorTechnicalParameters,
    getElevatorTrips,
    listElevatorDestinations,
    listElevatorStops,
    listElevators,
    registerElevators,
    remoteControlCall,
    remoteMove,
    restoreElevatorEmergency,
    updateElevatorAdvanced,
    updateElevatorDestinations,
    updateElevatorHandoff,
    updateElevatorOperationMode,
    updateElevatorProfile,
    updateElevatorSensors,
    updateElevatorStops,
    updateElevatorSystemPlate,
    updateElevatorTeam,
    updateElevatorTechnicalParameters,
    updateRemoteControlMode
};
