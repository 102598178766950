import type {IMenuBaseProps} from '@mcal/core-react';
import {
    ProfileContext,
    appSelectors,
    appThunks,
    cn,
    sessionThunks,
    useDispatch,
    useSelector,
    useTranslation,
    userSelectors,
    userThunks
} from '@mcal/core-react';
import type {FC} from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {createTestIDs} from '../../dev/index.js';
import {useOnClickOutside} from '../../hooks/use-on-click-outside/use-on-click-outside.js';
import {CloseSvg} from '../../icons/close.svg.js';
import {DarkSvg} from '../../icons/dark.svg.js';
import {DoubleChevronSvg} from '../../icons/double-chevron.svg.js';
import {LightSvg} from '../../icons/light.svg.js';
import {MenuSvg} from '../../icons/menu.svg.js';
import {SystemSvg} from '../../icons/system.svg.js';
import {Avatar} from '../avatar/avatar.js';
import {BottomBar} from '../bottom-bar/bottom-bar.js';
import {Box} from '../box/box.js';
import {Breadcrumbs} from '../breadcrumbs/breadcrumbs.js';
import {Button} from '../button/button.js';
import {Flex} from '../flex/flex.js';
import type {IIconProps} from '../icon/icon.js';
import {Label} from '../label/label.js';
import {Link} from '../link/link.js';
import {McallinnLogo} from '../mcallinn-logo/mcallinn-logo.js';
import {Select} from '../select/select.js';
import {Sidebar} from '../sidebar/sidebar.js';
import {SidebarPopup} from '../sidebar-popup/sidebar-popup.js';
import {SiteButton} from '../site-button/site-button.js';
import {
    StyledContainer,
    StyledLogoViewportLarge,
    StyledLogoViewportSmall,
    StyledProfile,
    StyledRoot,
    StyledViewportLarge,
    StyledViewportSmall
} from './menu.styles.js';
import {
    StyledContent,
    StyledLoggedOutButton,
    StyledRootSidebarRight,
    StyledSelectTheme,
    StyledSelectThemeText
} from './sidebar.styles.js';

const LanguageSelect: FC = () => {
    const dispatch = useDispatch();

    const {i18n} = useTranslation();

    const appLanguages = useSelector(appSelectors.selectAppLanguages);

    const options = useMemo(() => {
        return Object.entries(appLanguages).map(([key, language]) => {
            return {
                value: key,
                label: language.nativeName
            };
        });
    }, [appLanguages]);

    const onChangeHandler = useCallback(
        (key: string) => {
            void dispatch(appThunks.changeLanguage(key));
        },
        [dispatch]
    );

    return (
        <Select
            value={i18n.language}
            options={options}
            onChange={onChangeHandler}
        />
    );
};

interface IMenuProps extends IMenuBaseProps<typeof ownTestIDs> {
    links: {icon: IIconProps['icon']; label: string; link: string}[];
}

const SelectTheme: FC = () => {
    const dispatch = useDispatch();

    const userGeneralSettings = useSelector(
        userSelectors.selectUserGeneralSettings
    );

    const active = userGeneralSettings.themeType;

    return (
        <>
            <div style={{cursor: 'pointer'}}>
                <StyledSelectTheme
                    active={active === 'light'}
                    onClick={() => {
                        if (active !== 'light') {
                            void dispatch(
                                userThunks.updateGeneralSettings({
                                    themeType: 'light'
                                })
                            );
                        }
                    }}
                >
                    <LightSvg />
                </StyledSelectTheme>
                <StyledSelectThemeText
                    type={'extraSmall'}
                    active={active === 'light'}
                >
                    {'Light'}
                </StyledSelectThemeText>
            </div>

            <div style={{cursor: 'pointer'}}>
                <StyledSelectTheme
                    active={active === 'dark'}
                    onClick={() => {
                        if (active !== 'dark') {
                            void dispatch(
                                userThunks.updateGeneralSettings({
                                    themeType: 'dark'
                                })
                            );
                        }
                    }}
                >
                    <DarkSvg />
                </StyledSelectTheme>
                <StyledSelectThemeText
                    type={'extraSmall'}
                    active={active === 'dark'}
                >
                    {'Dark'}
                </StyledSelectThemeText>
            </div>

            <div style={{cursor: 'pointer'}}>
                <StyledSelectTheme
                    active={active === 'auto'}
                    onClick={() => {
                        if (active !== 'auto') {
                            void dispatch(
                                userThunks.updateGeneralSettings({
                                    themeType: 'auto'
                                })
                            );
                        }
                    }}
                >
                    <SystemSvg />
                </StyledSelectTheme>
                <StyledSelectThemeText
                    type={'extraSmall'}
                    active={active === 'auto'}
                >
                    {'System'}
                </StyledSelectThemeText>
            </div>
        </>
    );
};

interface ISidebarRightProps {
    setOpen: (open: boolean) => void;
}

const SidebarRight: FC<ISidebarRightProps> = ({setOpen}) => {
    const {firstName, lastName} = useContext(ProfileContext);

    const navigate = useNavigate();

    const email = useSelector(userSelectors.selectUserEmail);
    const isLogged = useSelector(userSelectors.selectUserIsLogged);

    const dispatch = useDispatch();

    return (
        <StyledRootSidebarRight>
            <Flex
                flexGrow={0}
                display={'flex'}
                flexDirection={'column'}
                gap={'16px'}
                padding={'p6'}
            >
                <Flex
                    flexGrow={0}
                    display={'flex'}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                >
                    <Avatar size={'medium'} currentUser={true} />

                    <div
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseSvg />
                    </div>
                </Flex>

                <div>
                    <p style={{margin: '0px'}}>
                        {firstName} {lastName}
                    </p>
                    <Label type={'extraSmall'}>{email.value}</Label>
                </div>

                <Button
                    label={'Manage Mcallinn ID'}
                    onClick={() => {
                        navigate('/user-settings');
                        setOpen(false);
                    }}
                    emphasis={'low'}
                    size={'small'}
                    variant={'outlined'}
                    color={'neutral'}
                />
            </Flex>

            <StyledContent>
                <SelectTheme />
            </StyledContent>

            <StyledContent>
                <LanguageSelect />
            </StyledContent>

            <StyledContent>
                <Link to={'/invites'}>{'Invites'}</Link>
            </StyledContent>

            {isLogged ? (
                <StyledContent>
                    <StyledLoggedOutButton
                        label={'Log out'}
                        onClick={() => {
                            void dispatch(sessionThunks.logOut()).finally(
                                () => {
                                    navigate('/log-in');
                                }
                            );
                        }}
                        emphasis={'low'}
                        size={'small'}
                        variant={'text'}
                        color={'neutral'}
                    />
                </StyledContent>
            ) : (
                <>
                    <StyledContent>
                        <Link to={'/sign-up'}>{'Sign Up'}</Link>
                    </StyledContent>

                    <StyledContent>
                        <Link to={'/log-in'}>{'Log In'}</Link>
                    </StyledContent>
                </>
            )}
        </StyledRootSidebarRight>
    );
};

const ownTestIDs = createTestIDs('Menu', ['root']);

const Menu: FC<IMenuProps> = ({
    className = '',
    classes = {},
    testIDs = {},
    links
}) => {
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const onClickOutsideHandler = useCallback(() => {
        setOpen(false);
    }, []);

    const bind = useOnClickOutside<HTMLDivElement>(onClickOutsideHandler);

    return (
        <>
            <StyledRoot
                data-testid={testIDs.root || ownTestIDs.root}
                className={cn(className, classes.root)}
            >
                <StyledContainer>
                    <Flex justifyContent={'flex-start'}>
                        <StyledLogoViewportLarge>
                            <McallinnLogo variant={'full'} />
                        </StyledLogoViewportLarge>

                        <StyledLogoViewportSmall>
                            <McallinnLogo variant={'icon'} />
                        </StyledLogoViewportSmall>

                        <StyledViewportLarge>
                            <SiteButton
                                onClick={() =>
                                    setExpanded((currentExpanded) => {
                                        return !currentExpanded;
                                    })
                                }
                            >
                                <DoubleChevronSvg
                                    direction={!expanded ? 'right' : 'left'}
                                />
                            </SiteButton>
                        </StyledViewportLarge>

                        <StyledViewportLarge>
                            <Box margin={{left: 'm5'}}>
                                <Breadcrumbs />
                            </Box>
                        </StyledViewportLarge>
                    </Flex>

                    <StyledProfile onClick={() => setOpen(true)}>
                        <Avatar size={'small'} currentUser={true} />
                        <MenuSvg />
                    </StyledProfile>
                </StyledContainer>

                <StyledViewportSmall>
                    <Box margin={{left: 'm5'}}>
                        <Breadcrumbs />
                    </Box>
                </StyledViewportSmall>
            </StyledRoot>

            <Sidebar
                links={links}
                expanded={expanded}
                setExpanded={setExpanded}
            />

            <BottomBar links={links} />

            {open && (
                <SidebarPopup {...bind}>
                    <SidebarRight setOpen={setOpen} />
                </SidebarPopup>
            )}
        </>
    );
};

export type {IMenuProps};
export {Menu, ownTestIDs as testIDs};
