// SocketStore.tsx
import type {FC, ReactNode} from 'react';
import {createContext, useContext, useEffect, useState} from 'react';
import {getSocketInstance} from './utils/get-socket-instance.js';

interface ISocketContext {
    socket: WebSocket;
}

const SocketContext = createContext<ISocketContext | null>(null);

interface ISocketStoreProps {
    children: ReactNode;
    fallback?: ReactNode;
}

const SocketStore: FC<ISocketStoreProps> = ({children, fallback = null}) => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        getSocketInstance()
            .then((connectedSocket) => {
                setSocket(connectedSocket);
            })
            .catch((err: Error) => {
                setError(err);
            });
    }, []);

    if (error) {
        return <div>{'Error connecting to WebSocket'}</div>;
    }

    if (!socket) {
        return <>{fallback}</>;
    }

    return (
        <SocketContext.Provider value={{socket}}>
            {children}
        </SocketContext.Provider>
    );
};

const useSocket = (): WebSocket => {
    const context = useContext(SocketContext);

    if (!context) {
        throw new Error('useSocket must be used within a SocketStore');
    }

    return context.socket;
};

export type {ISocketContext, ISocketStoreProps};
export {SocketContext, SocketStore, useSocket};
