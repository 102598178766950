import type {ILink, TTestIDs} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {FC} from 'react';
import {useCallback} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {createTestIDs} from '../../dev/index.js';
import {useGeneratePath} from '../../hooks/use-generate-path/use-generate-path.js';
import {SidebarItem} from './components/sidebar-item/sidebar-item.js';
import {StyledRoot} from './sidebar.styles.js';

const ownTestIDs = createTestIDs('Sidebar', ['root']);

interface ISidebarProps {
    expanded?: boolean;
    setExpanded?: (expanded: boolean) => void;
    links: ILink[];
    testIDs?: TTestIDs<typeof ownTestIDs>;
    className?: string;
    classes?: {
        root?: string;
    };
}

const Sidebar: FC<ISidebarProps> = ({
    expanded = false,
    setExpanded = (): void => {},
    className = '',
    classes = {},
    links = [],
    testIDs = ownTestIDs
}) => {
    const location = useLocation();
    const generatePath = useGeneratePath();

    const checkIfActive = useCallback(
        (link: ILink): boolean =>
            !link.alwaysInactive &&
            (!!matchPath(generatePath(link.link), location.pathname) ||
                (!!link.link &&
                    location.pathname.includes(generatePath(link.link)))),
        [generatePath, location.pathname]
    );

    return (
        <StyledRoot
            data-testid={testIDs.root}
            className={cn(className, classes.root)}
            expanded={expanded}
            onDoubleClick={(): void => setExpanded(!expanded)}
        >
            {links.map((link, index) => (
                <SidebarItem
                    icon={link.icon}
                    active={checkIfActive(link)}
                    expanded={expanded}
                    key={index}
                    to={generatePath(link.link)}
                    label={link.label}
                />
            ))}
        </StyledRoot>
    );
};

export type {ISidebarProps};
export {Sidebar, ownTestIDs as testIDs};
