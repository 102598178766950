import type {EEntityType, EIndividualType} from './permissions.js';

interface IDocument {
    documentId: string;
    owner: string;
    ownerType: EEntityType | EIndividualType;
    name: string;
    contentType: string;
    url: string;
}

interface IImage {
    imageId: string;
    owner: string;
    ownerType: EEntityType | EIndividualType;
    name: string;
    contentType: string;
    url: string;
}

interface ITemplate<
    TOwnerType extends EEntityType | EIndividualType,
    TContent extends object
> {
    templateId: string;
    owner: string;
    ownerType: TOwnerType;
    name: string;
    contentType: string;
    content: TContent;
}

interface IAddress {
    country: string | null;
    city: string | null;
    street: string | null;
    number: string | null;
    zip: string | null;
    lat: number | null;
    lng: number | null;
}

type TThemePack = 'standard' | 'codistica';

const defaultThemePack: TThemePack = 'standard';

type TThemeType = 'auto' | 'light' | 'dark';

const defaultThemeType: TThemeType = 'auto';

export type {IAddress, IDocument, IImage, ITemplate, TThemePack, TThemeType};
export {defaultThemePack, defaultThemeType};
