import {logger} from '@mcal/core';
import type {AxiosRequestConfig, AxiosResponse} from 'axios';
import {AxiosError, Axios as Base, default as def} from 'axios';

const log = logger.withCaller('Axios');

interface IAxiosSetupOptions {
    baseUrl: string | undefined;
    ignore: string[];
    onTokenRefresh: () => Promise<void>;
}

class Axios extends Base {
    private refreshing: null | Promise<void>;

    status: 'INIT' | 'SETUP';

    constructor(config: AxiosRequestConfig) {
        const {headers, ...rest} = def.defaults;

        super({
            ...rest,
            ...config
        });

        this.defaults.headers = headers;

        this.refreshing = null;
        this.status = 'INIT';
    }

    request<
        TResBody = unknown,
        TRes = AxiosResponse<TResBody>,
        TReqBody = unknown
    >(config: AxiosRequestConfig<TReqBody>): Promise<TRes> {
        if (this.status !== 'SETUP') {
            log.warn('AXIOS SERVICE NOT SETUP')();
        }

        return super.request(config);
    }

    setup(options: IAxiosSetupOptions): void {
        if (this.status !== 'INIT') {
            log.warn('AXIOS SERVICE ALREADY SETUP')();
            return;
        }

        this.defaults.baseURL = options.baseUrl;

        // REFRESH TOKEN AND RETRY REQUESTS AUTOMATICALLY
        this.interceptors.response.use(null, async (error: AxiosError) => {
            const {config, response} = error;

            let skipLog = false;

            if (config && response) {
                const {url} = config;
                const {status} = response;

                if (status === 401) {
                    if (Axios.shouldIntercept(url, options.ignore)) {
                        log.debug(`INTERCEPTING 401 ERROR - URL: ${url}`)();

                        if (this.refreshing) {
                            log.debug(
                                'REUSING EXISTENT REFRESH TOKEN PROMISE'
                            )();
                        } else {
                            log.debug('REFRESHING TOKEN')();

                            this.refreshing = options
                                .onTokenRefresh()
                                .finally(() => {
                                    this.refreshing = null;
                                });
                        }

                        return this.refreshing
                            .catch(() => {
                                log.debug(
                                    `FAILING REQUEST AFTER REFRESH TOKEN FAILED - URL: ${url}`
                                )();

                                return Promise.reject(error);
                            })
                            .then(() => {
                                log.debug(
                                    `RETRYING REQUEST AFTER REFRESH TOKEN SUCCEEDED - URL: ${url}`
                                )();

                                return this.request(config);
                            });
                    } else {
                        skipLog = true;
                    }
                } else if (status === 403) {
                    skipLog = true;
                }
            }

            if (error.code === 'ERR_CANCELED') {
                log.debug(`CANCELLED: ${config ? config.url : 'unknown'}`)();
            } else if (!skipLog) {
                log.error(error)();
            }

            return Promise.reject(error);
        });

        // HOLD REQUESTS WHILE TOKEN IS REFRESHED
        this.interceptors.request.use(async (config) => {
            const {url} = config;

            if (
                !this.refreshing ||
                !Axios.shouldIntercept(url, options.ignore)
            ) {
                return config;
            }

            log.debug(
                `HOLDING REQUEST WHILE TOKEN IS REFRESHED - URL: ${url}`
            )();

            return this.refreshing
                .catch(() => {
                    log.debug(
                        `RELEASING REQUEST AFTER REFRESH TOKEN FAILED - URL: ${url}`
                    )();

                    return config;
                })
                .then(() => {
                    log.debug(
                        `RELEASING REQUEST AFTER REFRESH TOKEN SUCCEEDED - URL: ${url}`
                    )();

                    return config;
                });
        });

        this.status = 'SETUP';
    }

    private static shouldIntercept(
        url: string | undefined,
        ignore: string[]
    ): boolean {
        if (url) {
            return !ignore.some((item) => {
                return url.startsWith(item);
            });
        } else {
            return false;
        }
    }
}

const axios = new Axios({
    withCredentials: true
});

export type {AxiosRequestConfig, AxiosResponse, IAxiosSetupOptions};
export {Axios, AxiosError, axios};
