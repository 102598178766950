import type {IEntity} from '@mcal/core';
import {EEntityType} from '@mcal/core';
import type {
    IRouteWrapperProps,
    TAsyncThunkAction,
    TEntitySliceStatus,
    TSelector
} from '@mcal/core-react';
import {
    serviceCompanySelectors,
    serviceCompanyThunks,
    siteSelectors,
    siteThunks,
    useDispatch,
    useSelector
} from '@mcal/core-react';
import type {FC} from 'react';
import {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useSpinner} from '../../hooks/use-spinner/use-spinner.js';

type TEntity = EEntityType.ServiceCompany | EEntityType.Site;

interface ISelectors {
    entitySelector: TSelector<IEntity<TEntity>>;
    entityStatusSelector: TSelector<TEntitySliceStatus>;
}

const selectorsMap: Record<TEntity, ISelectors> = {
    [EEntityType.ServiceCompany]: {
        entitySelector: serviceCompanySelectors.selectServiceCompanySummary,
        entityStatusSelector: serviceCompanySelectors.selectServiceCompanyStatus
    },
    [EEntityType.Site]: {
        entitySelector: siteSelectors.selectSiteSummary,
        entityStatusSelector: siteSelectors.selectSiteStatus
    }
};

interface IThunks {
    getEntity: (arg: {entityId: string}) => TAsyncThunkAction;
    getPermissions: (arg: {entityId: string}) => TAsyncThunkAction;
}

const thunksMap: Record<TEntity, IThunks> = {
    [EEntityType.ServiceCompany]: {
        getEntity: ({entityId}) => {
            return serviceCompanyThunks.getServiceCompany({
                serviceCompanyId: entityId
            });
        },
        getPermissions: ({entityId}) => {
            return serviceCompanyThunks.getPermissions({
                entityId,
                affinity: null
            });
        }
    },
    [EEntityType.Site]: {
        getEntity: ({entityId}) => {
            return siteThunks.getSite({siteId: entityId});
        },
        getPermissions: ({entityId}) => {
            return siteThunks.getPermissions({entityId, affinity: null});
        }
    }
};

const RouteWrapper: FC<IRouteWrapperProps> = ({children, entityType}) => {
    const {entitySelector, entityStatusSelector} = useMemo<ISelectors>(() => {
        return selectorsMap[entityType];
    }, [entityType]);

    const {getEntity, getPermissions} = useMemo<IThunks>(() => {
        return thunksMap[entityType];
    }, [entityType]);

    const entity = useSelector(entitySelector);

    const entityStatus = useSelector(entityStatusSelector);

    const params = useParams();

    const dispatch = useDispatch();

    const entityId = useMemo<string>(() => {
        if (entityType === EEntityType.ServiceCompany) {
            if (params.serviceCompanyId) {
                return params.serviceCompanyId;
            } else {
                throw new Error('SERVICE COMPANY ID IS REQUIRED');
            }
        } else {
            if (params.siteId) {
                return params.siteId;
            } else {
                throw new Error('SITE ID IS REQUIRED');
            }
        }
    }, [entityType, params.serviceCompanyId, params.siteId]);

    useSpinner([entityStatus === 'LOADING']);

    useEffect(() => {
        if (
            (entityStatus === 'IDLE' || entityStatus === 'INIT') &&
            (!entity.entityId || entity.entityId !== entityId)
        ) {
            void dispatch(getEntity({entityId})).then(() => {
                void dispatch(getPermissions({entityId}));
            });
        }
    }, [
        dispatch,
        entity.entityId,
        entityId,
        entityStatus,
        getEntity,
        getPermissions
    ]);

    return children;
};

export {RouteWrapper};
