import type {TTestIDs} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {FC, ReactNode} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledButton} from './site-button.styles.js';

const ownTestIDs = createTestIDs('SIteButton', ['root']);

interface ISiteButtonProps {
    children?: ReactNode;
    className?: string;
    classes?: {
        root?: string;
    };
    testIDs?: TTestIDs<typeof ownTestIDs>;
    onClick: () => void;
    variant?: 'borderless' | 'outlined';
}

const SiteButton: FC<ISiteButtonProps> = ({
    testIDs = ownTestIDs,
    className = '',
    classes = {},
    onClick,
    children,
    variant
}) => {
    return (
        <StyledButton
            data-testid={testIDs.root}
            className={cn(className, classes.root)}
            onClick={() => onClick()}
            variant={variant}
        >
            {children}
        </StyledButton>
    );
};

export type {ISiteButtonProps};
export {SiteButton, ownTestIDs as testIDs};
