import {styled} from '../../utils/styled/styled.js';

const StyledRoot = styled('div')(({theme}) => ({
    position: 'fixed',
    right: '0',
    background: theme.palette.surface.base.lowest,
    height: '100vh',
    top: '0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.neutral.divide}`,
    zIndex: theme.depth.global.z7,

    [theme.query.rtl]: {
        left: '0',
        right: 'auto'
    }
}));

export {StyledRoot};
