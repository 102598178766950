import type {
    EEntityType,
    EServiceCompanyRole,
    IElevatorSummary,
    IEntityInviteEntry,
    IResolvedPermissions,
    IServiceCompanyGeneralSettings,
    IServiceCompanyProfile,
    IServiceCompanyState,
    IServiceCompanySummary,
    ISiteSummary,
    ITeamMembership,
    IUserMembership,
    TTechnicalParametersTemplate
} from '@mcal/core';
import {createSlice} from '@reduxjs/toolkit';
import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {getUsersMemberships} from '../../services/app-node-auth/users/users.thunks.js';
import {listElevators} from '../../services/app-node-elevators/elevators/elevators.thunks.js';
import {
    cancelInvite,
    listEntityInvites,
    sendInvite
} from '../../services/app-node-invites/invites/invites.thunks.js';
import {getMileageTotal} from '../../services/app-node-kpis/kpis/kpis.thunks.js';
import {
    createServiceCompanyTechnicalParametersTemplates,
    deleteServiceCompanyTeam,
    getServiceCompany,
    getServiceCompanyState,
    getServiceCompanyTeam,
    getServiceCompanyTechnicalParametersTemplate,
    listServiceCompanyConfigurationsTemplates,
    updateServiceCompanyGeneralSettings,
    updateServiceCompanyProfile,
    updateServiceCompanyTeam,
    updateServiceCompanyTechnicalParametersTemplates
} from '../../services/app-node-service-companies/service-companies/service-companies.thunks.js';
import {
    createSite,
    listSites
} from '../../services/app-node-sites/sites/sites.thunks.js';
import {fulfilledRemoteUpdate} from '../../utils/fulfilled-remote-update/fulfilled-remote-update.js';
import {pendingRemoteUpdate} from '../../utils/pending-remote-update/pending-remote-update.js';
import {rejectedRemoteUpdate} from '../../utils/rejected-remote-update/rejected-remote-update.js';
import type {TInvites} from './service-company.state.js';
import {initialState} from './service-company.state.js';

const serviceCompanySlice = createSlice({
    name: 'serviceCompany',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // GET SERVICE COMPANY
        builder.addCase(getServiceCompany.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['serviceCompanySummary']
            });
        });

        builder.addCase(
            getServiceCompany.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'serviceCompanySummary',
                            replacer: (data): IServiceCompanySummary => {
                                return data.summary;
                            }
                        },
                        {
                            key: 'serviceCompanyState',
                            replacer: (data): IServiceCompanyState => {
                                return data.state;
                            }
                        },
                        {
                            key: 'serviceCompanyProfile',
                            replacer: (data): IServiceCompanyProfile => {
                                return data.profile;
                            }
                        },
                        {
                            key: 'serviceCompanyGeneralSettings',
                            replacer: (
                                data
                            ): IServiceCompanyGeneralSettings => {
                                return data.generalSettings;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getServiceCompany.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        'serviceCompanySummary',
                        'serviceCompanyState',
                        'serviceCompanyProfile',
                        'serviceCompanyGeneralSettings'
                    ]
                });
            }
        );

        // GET SERVICE COMPANY STATE
        builder.addCase(getServiceCompanyState.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['serviceCompanyState']
            });
        });

        builder.addCase(
            getServiceCompanyState.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'serviceCompanyState',
                            replacer: (data): IServiceCompanyState => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getServiceCompanyState.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanyState']
                });
            }
        );

        // UPDATE SERVICE COMPANY GENERAL SETTINGS
        builder.addCase(
            updateServiceCompanyGeneralSettings.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: [
                        {
                            key: 'serviceCompanyGeneralSettings',
                            replacer: (
                                arg,
                                current
                            ): IServiceCompanyGeneralSettings => {
                                return {
                                    ...current,
                                    ...arg.settings
                                };
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateServiceCompanyGeneralSettings.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanyGeneralSettings']
                });
            }
        );

        builder.addCase(
            updateServiceCompanyGeneralSettings.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanyGeneralSettings']
                });
            }
        );

        // UPDATE SERVICE COMPANY PROFILE

        builder.addCase(
            updateServiceCompanyProfile.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: [
                        {
                            key: 'serviceCompanyProfile',
                            replacer: (
                                arg,
                                current
                            ): IServiceCompanyProfile => {
                                return {
                                    ...current,
                                    ...arg
                                };
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateServiceCompanyProfile.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanyProfile']
                });
            }
        );
        builder.addCase(
            updateServiceCompanyProfile.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanyProfile']
                });
            }
        );

        // GET SERVICE COMPANY TECHNICAL PARAMS LIST
        builder.addCase(
            listServiceCompanyConfigurationsTemplates.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['templates']
                });
            }
        );

        builder.addCase(
            listServiceCompanyConfigurationsTemplates.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'templates',
                            replacer: (
                                data
                            ): TTechnicalParametersTemplate[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            listServiceCompanyConfigurationsTemplates.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['templates']
                });
            }
        );

        // GET SERVICE COMPANY TECHNICAL PARAMS TEMPLATE
        builder.addCase(
            getServiceCompanyTechnicalParametersTemplate.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['templates']
                });
            }
        );

        builder.addCase(
            getServiceCompanyTechnicalParametersTemplate.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'templates',
                            mutator: (arg, current): void => {
                                const existingTemplate = current.find(
                                    (template) =>
                                        template.templateId === arg.templateId
                                );
                                if (existingTemplate) {
                                    existingTemplate.content = arg.content;
                                } else {
                                    current.push(arg);
                                }
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            getServiceCompanyTechnicalParametersTemplate.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['templates']
                });
            }
        );

        // UPDATE SERVICE COMPANY TECHNICAL PARAMS TEMPLATES
        builder.addCase(
            updateServiceCompanyTechnicalParametersTemplates.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['templates']
                });
            }
        );

        builder.addCase(
            updateServiceCompanyTechnicalParametersTemplates.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'templates',
                            mutator: (arg, current): void => {
                                const existingTemplate = current.find(
                                    (template) =>
                                        template.templateId === arg.templateId
                                );
                                if (existingTemplate) {
                                    existingTemplate.content = arg.content;
                                }
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            updateServiceCompanyTechnicalParametersTemplates.rejected,
            (state, {payload, meta}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['templates']
                });
            }
        );

        // CREATE SERVICE COMPANY TECHNICAL PARAMS TEMPLATES
        builder.addCase(
            createServiceCompanyTechnicalParametersTemplates.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['templates']
                });
            }
        );

        builder.addCase(
            createServiceCompanyTechnicalParametersTemplates.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'templates',
                            mutator: (arg, current): void => {
                                current.push(arg);
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            createServiceCompanyTechnicalParametersTemplates.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['templates']
                });
            }
        );
        // SERVICE COMPANY TEAM
        builder.addCase(getServiceCompanyTeam.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['team']
            });
        });

        builder.addCase(
            getServiceCompanyTeam.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.serviceCompanySummary.current.entityId &&
                    meta.arg.serviceCompanyId !==
                        state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'team',
                            replacer: (
                                data
                            ): ITeamMembership<EEntityType.ServiceCompany>[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getServiceCompanyTeam.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );

        // USER MEMBERSHIP HANDLING
        builder.addCase(getUsersMemberships.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['memberships']
            });
        });
        builder.addCase(
            getUsersMemberships.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.serviceCompanySummary.current.entityId &&
                    meta.arg.entityId !==
                        state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'memberships',
                            replacer: (data): IUserMembership[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getUsersMemberships.rejected,
            (state, {meta, payload}) => {
                if (
                    meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['memberships']
                });
            }
        );
        // LIST ENTITIES
        builder.addCase(listEntityInvites.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['invites']
            });
        });
        builder.addCase(
            listEntityInvites.fulfilled,
            (state, {payload, meta}) => {
                if (
                    meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'invites',
                            replacer: (
                                data
                            ): IEntityInviteEntry<EEntityType.ServiceCompany>[] => {
                                return data as IEntityInviteEntry<EEntityType.ServiceCompany>[];
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(
            listEntityInvites.rejected,
            (state, {meta, payload}) => {
                if (
                    meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['invites']
                });
            }
        );

        // LIST SITES
        builder.addCase(listSites.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['sites']
            });
        });

        builder.addCase(listSites.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'sites',
                        replacer: (data): ISiteSummary[] => {
                            return data;
                        }
                    }
                ]
            });
        });

        builder.addCase(listSites.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['sites']
            });
        });

        // GET TOTAL MILEAGE
        builder.addCase(getMileageTotal.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(getMileageTotal.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'kpis',
                        mutator: (arg, current): void => {
                            current.mileageTotal = arg;
                        }
                    }
                ]
            });
        });

        builder.addCase(getMileageTotal.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['kpis']
            });
        });

        builder.addCase(getPermissions.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['permissions']
            });
        });

        builder.addCase(getPermissions.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'permissions',
                        replacer: (data): IResolvedPermissions => {
                            return data;
                        }
                    }
                ]
            });
        });

        builder.addCase(getPermissions.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['permissions']
            });
        });

        // HANDLING SEND INVITE

        builder.addCase(sendInvite.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'invites',
                        replacer: (arg, current): TInvites => {
                            const now = Date.now();

                            const newInvites: TInvites = arg.assignments.map(
                                (assignment) => {
                                    return {
                                        inviteId: '',
                                        userId: null,
                                        userEmail: assignment.userEmail,
                                        role: assignment.role as EServiceCompanyRole,
                                        createdAt: now
                                    };
                                }
                            );

                            return [...current, ...newInvites];
                        }
                    }
                ]
            });
        });

        builder.addCase(sendInvite.fulfilled, (state, {meta, payload}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'invites',
                        replacer: (arg, current): TInvites => {
                            return [
                                ...(current || []),
                                ...(Object.values(arg).filter(
                                    Boolean
                                ) as TInvites)
                            ];
                        }
                    }
                ]
            });
        });
        builder.addCase(sendInvite.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.entityId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        // HANDLING CANCEL INVITE
        builder.addCase(cancelInvite.pending, (state, {meta}) => {
            const isAffected = state.remotes.invites.current.some((invite) => {
                return invite.inviteId === meta.arg.inviteId;
            });

            if (!isAffected) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['invites']
            });
        });

        builder.addCase(cancelInvite.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'invites',
                        replacer: (_, current): TInvites => {
                            return current.filter(
                                (invite) =>
                                    invite.inviteId !== meta.arg.inviteId
                            );
                        }
                    }
                ]
            });
        });

        builder.addCase(cancelInvite.rejected, (state, {meta, payload}) => {
            const isAffected = state.remotes.invites.current.some((invite) => {
                return invite.inviteId === meta.arg.inviteId;
            });

            if (!isAffected) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        // UPDATE SC TEAM
        builder.addCase(updateServiceCompanyTeam.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'team',
                        replacer: (
                            arg,
                            current
                        ): ITeamMembership<EEntityType.ServiceCompany>[] => {
                            return current.map((member) => {
                                if (member.userId === arg.userId) {
                                    return {
                                        ...member,
                                        role: arg.role
                                    };
                                } else {
                                    return member;
                                }
                            });
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updateServiceCompanyTeam.fulfilled,
            (state, {meta, payload}) => {
                if (
                    state.remotes.serviceCompanySummary.current.entityId &&
                    meta.arg.serviceCompanyId !==
                        state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );

        builder.addCase(
            updateServiceCompanyTeam.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.serviceCompanySummary.current.entityId &&
                    meta.arg.serviceCompanyId !==
                        state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );

        builder.addCase(deleteServiceCompanyTeam.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'team',
                        replacer: (
                            arg,
                            current
                        ): ITeamMembership<EEntityType.ServiceCompany>[] => {
                            return current.filter((member) => {
                                return member.userId !== arg.userId;
                            });
                        }
                    }
                ]
            });
        });

        builder.addCase(
            deleteServiceCompanyTeam.fulfilled,
            (state, {meta, payload}) => {
                if (
                    state.remotes.serviceCompanySummary.current.entityId &&
                    meta.arg.serviceCompanyId !==
                        state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );
        builder.addCase(
            deleteServiceCompanyTeam.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.serviceCompanySummary.current.entityId &&
                    meta.arg.serviceCompanyId !==
                        state.remotes.serviceCompanySummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );

        builder.addCase(listElevators.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevators']
            });
        });
        builder.addCase(listElevators.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'elevators',
                        replacer: (data): IElevatorSummary[] => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(listElevators.rejected, (state, {payload, meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.target !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['elevators']
            });
        });

        builder.addCase(createSite.pending, (state, {meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['sites']
            });
        });
        builder.addCase(createSite.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'sites',
                        replacer: (data, current): ISiteSummary[] => {
                            return [
                                ...(current as unknown as ISiteSummary[]),
                                data.summary
                            ];
                        }
                    }
                ]
            });
        });
        builder.addCase(createSite.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.serviceCompanySummary.current.entityId &&
                meta.arg.serviceCompanyId !==
                    state.remotes.serviceCompanySummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['sites']
            });
        });
    }
});

export {serviceCompanySlice};
