import type {TTestIDs} from '@mcal/core-react';
import type {FC} from 'react';
import {createTestIDs} from '../../../../dev/index.js';
import type {IIconProps} from '../../../icon/icon.js';
import {
    StyledActiveIndicator,
    StyledContainer,
    StyledIcon,
    StyledLabel,
    StyledRoot
} from './bottom-bar-item.styles.js';

const ownTestIDs = createTestIDs('BottomBarItem', ['root']);

interface IBottomBarItemProps {
    active?: boolean;
    to: string;
    label: string;
    icon?: IIconProps['icon'];
    testIDs?: TTestIDs<typeof ownTestIDs>;
    className?: string;
    classes?: {
        root?: string;
    };
}

const BottomBarItem: FC<IBottomBarItemProps> = ({
    to = '',
    label,
    active = false,
    icon,
    testIDs = ownTestIDs,
    className = '',
    classes = {}
}) => {
    return (
        <StyledRoot
            className={className}
            classes={classes}
            $active={active}
            to={to}
            testIDs={testIDs}
        >
            <StyledActiveIndicator $active={active} />
            <StyledContainer $active={active}>
                {icon && (
                    <StyledIcon
                        color={active ? 'primary' : 'neutral'}
                        emphasis={'high'}
                        icon={icon}
                        withBackground={false}
                    />
                )}
                <StyledLabel $active={active} type={'small'}>
                    {label}
                </StyledLabel>
            </StyledContainer>
        </StyledRoot>
    );
};

export type {IBottomBarItemProps};
export {BottomBarItem, ownTestIDs as testIDs};
