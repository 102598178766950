import {useMemo} from 'react';
import type {IThemePack} from '../../defines/theme.types.js';
import {serviceCompanySelectors} from '../../slices/service-company/service-company.index.js';
import {userSelectors} from '../../slices/user/user.index.js';
import {DEFAULT_THEME_PACK, THEME_PACKS} from '../../themes/index.js';
import {createThemePack} from '../../utils/create-theme-pack/create-theme-pack.js';
import {useSelector} from '../use-selector/use-selector.js';

function useThemePack(): IThemePack {
    const user = useSelector(userSelectors.selectUserGeneralSettings);

    const serviceCompany = useSelector(
        serviceCompanySelectors.selectServiceCompanyGeneralSettings
    );
    // Check selector for mobile

    const themePack = useMemo(() => {
        if (
            serviceCompany &&
            serviceCompany.customColor &&
            !serviceCompany.customColor.toLowerCase().includes('nan')
        ) {
            return createThemePack({
                key: `custom-${serviceCompany.customColor}`,
                colors: {
                    primary: serviceCompany.customColor,
                    secondary: '#525865',
                    tertiary: '#525865',
                    neutral: '#919094',
                    divergent: '#919094',
                    error: '#CC1906',
                    warning: '#F58220',
                    success: '#06CC65',
                    info: '#2B8DFE'
                }
            });
        } else {
            return THEME_PACKS.find(({key}) => key === user.themePack);
        }
    }, [serviceCompany, user.themePack]);

    if (themePack) {
        return themePack;
    } else {
        return DEFAULT_THEME_PACK;
    }
}

export {useThemePack};
