import type {
    IUserAuthSettings,
    IUserGeneralSettings,
    IUserInviteEntry,
    IUserProfile,
    IUserSummary,
    IUserTripSettings,
    TAffinityProvider
} from '@mcal/core';
import {
    defaultUserAuthSettings,
    defaultUserGeneralSettings,
    defaultUserProfile,
    defaultUserSummary,
    defaultUserTripSettings
} from '@mcal/core';
import type {ISliceRemote} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

interface IUserSliceState {
    isEmailVerified: boolean;
    isPhoneNumberVerified: boolean;
    remotes: {
        userSummary: ISliceRemote<IUserSummary>;
        userProfile: ISliceRemote<IUserProfile>;
        userGeneralSettings: ISliceRemote<IUserGeneralSettings>;
        userAuthSettings: ISliceRemote<IUserAuthSettings>;
        userTripSettings: ISliceRemote<IUserTripSettings>;
        invites: ISliceRemote<IUserInviteEntry<TAffinityProvider>[]>;
    };
}

const initialState = {
    isEmailVerified: false,
    isPhoneNumberVerified: false,
    remotes: {
        userSummary: createRemote(defaultUserSummary),
        userProfile: createRemote(defaultUserProfile),
        userGeneralSettings: createRemote(defaultUserGeneralSettings),
        userAuthSettings: createRemote(defaultUserAuthSettings),
        userTripSettings: createRemote(defaultUserTripSettings),
        invites: createRemote([])
    }
} satisfies IUserSliceState as IUserSliceState;

export type {IUserSliceState};
export {initialState};
