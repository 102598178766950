import {styled} from '../../utils/styled/styled.js';

interface IStyledButtonProps {
    variant?: 'borderless' | 'outlined';
}
const StyledButton = styled('button')<IStyledButtonProps>(
    ({theme}) => ({
        padding: `${theme.size.fixed.s0}`,
        borderRadius: theme.size.fixed.s0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.surface.onBase.low,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px'
    }),
    ({theme, variant}) => {
        if (variant === 'borderless') {
            return {
                background: theme.palette.surface.base.high,
                border: '1px solid',
                borderColor: theme.palette.surface.base.high,
                ':hover': {
                    background: theme.palette.surface.base.mid
                },
                ':active': {
                    background: theme.palette.surface.base.highest
                }
            };
        }
        return {
            background: 'transparent',
            border: '1px solid ',
            borderColor: theme.palette.neutral.accent,
            ':hover': {
                background: theme.palette.surface.base.mid
            },
            ':active': {
                background: theme.palette.surface.base.highest
            }
        };
    }
);

export {StyledButton};
