import type {
    EEntityType,
    IElevatorGroupSummary,
    IElevatorSummary,
    IResolvedPermissions,
    ISiteActivityEntry,
    ISiteProfile,
    ISiteState,
    ISiteSummary,
    TMileageTotalKPI
} from '@mcal/core';
import {createSelector} from '@reduxjs/toolkit';
import type {
    IGroupedElevators,
    ITeamMember
} from '../../defines/platform.types.js';
import type {
    ESliceRemoteStatus,
    IPartialState,
    TEntitySliceStatus
} from '../../defines/redux.types.js';
import {composeTeam} from '../../utils/compose-team/compose-team.js';
import type {ISiteSliceState} from './site.state.js';

const siteSelector = (state: IPartialState): ISiteSliceState => {
    if (!state.site) {
        throw new Error('Site slice is not available.');
    }

    return state.site;
};

const selectSiteSummary = createSelector(
    [siteSelector],
    (site): ISiteSummary => {
        return site.remotes.siteSummary.current;
    }
);

const selectSiteState = createSelector([siteSelector], (site): ISiteState => {
    return site.remotes.siteState.current;
});

const selectSiteProfile = createSelector(
    [siteSelector],
    (site): ISiteProfile => {
        return site.remotes.siteProfile.current;
    }
);

const selectSiteStatus = createSelector(
    [siteSelector],
    (site): TEntitySliceStatus => {
        return site.status;
    }
);

const selectSiteMileageTotal = createSelector(
    [siteSelector],
    (site): TMileageTotalKPI | null => {
        return site.remotes.kpis.current.mileageTotal;
    }
);

const selectElevatorGroups = createSelector(
    [siteSelector],
    (site): IElevatorGroupSummary[] => {
        return site.remotes.elevatorGroups.current;
    }
);

const selectElevators = createSelector(
    [siteSelector],
    (site): IElevatorSummary[] => {
        return site.remotes.elevators.current;
    }
);

const selectGroupedElevators = createSelector(
    [siteSelector],
    (site): IGroupedElevators[] => {
        const output: IGroupedElevators[] = [];

        site.remotes.elevatorGroups.current.forEach((elevatorGroup) => {
            output.push({
                elevatorGroup,
                elevators: site.remotes.elevators.current.filter((elevator) => {
                    return elevator.parentId === elevatorGroup.entityId;
                })
            });
        });

        return output;
    }
);

const selectGroupedElevatorsStatus = createSelector(
    [siteSelector],
    (site): ESliceRemoteStatus => {
        return site.remotes.elevatorGroups.status;
    }
);

const selectSitePermissions = createSelector(
    [siteSelector],
    (site): IResolvedPermissions => {
        return site.remotes.permissions.current;
    }
);

const selectSiteTeam = createSelector(
    [siteSelector, (_: IPartialState, affinity: string): string => affinity],
    (site, affinity): ITeamMember<EEntityType.Site>[] => {
        if (!affinity) {
            return [];
        }

        return composeTeam({
            team: site.remotes.teams.current[affinity] || [],
            memberships: site.remotes.memberships.current[affinity] || [],
            invites: site.remotes.invites.current
        });
    }
);

const selectSiteGlobalTeam = createSelector(
    [siteSelector, (_, affinity: string): string => affinity],
    (site, affinity): ITeamMember<EEntityType.ServiceCompany>[] => {
        if (!affinity) {
            return [];
        }

        return composeTeam({
            team: site.remotes.globalTeams.current[affinity] || [],
            memberships: site.remotes.globalMemberships.current[affinity] || []
        });
    }
);

const selectSiteActivity = createSelector(
    [siteSelector],
    (site): ISiteActivityEntry[] => {
        return site.remotes.activity.current.data;
    }
);

const selectSiteActivityStatus = createSelector(
    [siteSelector],
    (
        site
    ): {
        currentCursor: string | null;
        nextCursor: string | null;
        status: ESliceRemoteStatus;
    } => {
        return {
            currentCursor: site.remotes.activity.current.currentCursor,
            nextCursor: site.remotes.activity.current.nextCursor,
            status: site.remotes.activity.status
        };
    }
);

export {
    selectElevatorGroups,
    selectElevators,
    selectGroupedElevators,
    selectGroupedElevatorsStatus,
    selectSiteActivity,
    selectSiteActivityStatus,
    selectSiteGlobalTeam,
    selectSiteMileageTotal,
    selectSitePermissions,
    selectSiteProfile,
    selectSiteState,
    selectSiteStatus,
    selectSiteSummary,
    selectSiteTeam
};
